import React from 'react'
import { Error } from '~sections/utility'
import { PageWrapper } from '~components/Core'
import FooterSix from '~sections/Home/footerSix'
// import { Link } from '~components'
import { graphql } from 'gatsby'

const header = {
  headerClasses:
    'site-header site-header--menu-end dark-header site-header--sticky',
  containerFluid: false,
  darkLogo: false,
}

export default function errorPage() {
  return (
    <PageWrapper innerPage={true}>
      <Error />
      <FooterSix />
    </PageWrapper>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
